exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-featuredpartners-js": () => import("./../../../src/pages/featuredpartners.js" /* webpackChunkName: "component---src-pages-featuredpartners-js" */),
  "component---src-pages-preview-[slug]-js": () => import("./../../../src/pages/preview/[slug].js" /* webpackChunkName: "component---src-pages-preview-[slug]-js" */),
  "component---src-pages-preview-uuid-[slug]-js": () => import("./../../../src/pages/preview/uuid/[slug].js" /* webpackChunkName: "component---src-pages-preview-uuid-[slug]-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-sidebar-js": () => import("./../../../src/templates/sidebar.js" /* webpackChunkName: "component---src-templates-sidebar-js" */)
}

